body {
  background-color: #f8f9fa; /* Light background for better readability */
}

table {
  margin-top: 20px;
}

.dark {
  color: #343a40; /* Darker heading */
}
